import { Logger } from "@storefront/core/lib/logger";
import { loadCategoryDeepById } from "@storefront/core/data-resolver/categories";
//import store from "@/esf_lommel_dt1_europe/store";
import { i18n } from "@storefront/core/i18n";

const state = () => ({
  menuItems: [],
  menuState: [],
  navBar: false,
  headerState: "",
});

const actions = {
  toggleNavbar({ state, commit }) {
    if (state.navBar == true) {
      commit("setNavBar", false);
    } else {
      commit("setNavBar", true);
    }
  },
  async loadMenu({ commit }) {
    const category = await loadCategoryDeepById(2).catch((e) => {
      Logger.error("loadMenu", "menu.ts", e)();
      throw e;
    });
    const menu = [];
    category.children.sort((a, b) => {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
    category.children.forEach((child) => {
      if (child.include_in_menu == 1) {
        const item = {
          id: child.id,
          itemName: child.name,
          linkTo: "/" + child.url_path,
          hasDropMenu: false,
          dropMenu: [],
        };
        child.linkTo = "/" + child.url_path;

        /*         if (child.children_count > 0) {
          item.hasDropMenu = true;
        } else {
        }
 */ child.children.sort(
          (a, b) => {
            if (a.position < b.position) {
              return -1;
            }
            if (a.position > b.position) {
              return 1;
            }
            return 0;
          }
        );
        let iCount = 1;
        let subItems = [];
        child.children.forEach((sChild) => {
          if (sChild.include_in_menu == 1) {
            const sItem = {
              id: sChild.id,
              name: sChild.name,
              linkTo: "/" + sChild.url_path,
            };
            subItems.push(sItem);
            iCount++;
            if (iCount == 11) {
              item.dropMenu.push(subItems);
              subItems = [];
              iCount = 1;
            }
          }
        });
        if (subItems.length > 0 && iCount < 11) {
          item.dropMenu.push(subItems);
        }
        if (item.dropMenu.length > 0) {
          item.hasDropMenu = true;
          item.dropMenuState = "collapsed";
        }
        menu.push(item);
        commit("setMenuState", { id: item.id, state: false });
      }
    });
    let item = {
      id: 99995,
      itemName: i18n.t("DT1 Technology"),
      linkTo: "/" + i18n.t("dt1-technology"),
      hasDropMenu: false,
      dropMenu: [],
    }
    menu.push(item);
    commit("setMenuState", { id: item.id, state: false });
    item = {
      id: 99996,
      itemName: i18n.t("dealers"),
      linkTo: "/" + i18n.t("menu_dealers"),
      hasDropMenu: false,
      dropMenu: [],
    };
    menu.push(item);
    commit("setMenuState", { id: item.id, state: false });
    item = {
      id: 99997,
      itemName: i18n.t("teams"),
      linkTo: "/" + i18n.t("menu_teams"),
      hasDropMenu: false,
      dropMenu: [],
    };
    menu.push(item);
    commit("setMenuState", { id: item.id, state: false });
    item = {
      id: 99998,
      itemName: i18n.t("news"),
      linkTo: "/" + i18n.t("menu_news"),
      hasDropMenu: false,
      dropMenu: [],
    };
    menu.push(item);
    commit("setMenuState", { id: item.id, state: false });
    item = {
      id: 99999,
      itemName: i18n.t("contact"),
      linkTo: "/" + i18n.t("menu_contact"),
      hasDropMenu: false,
      dropMenu: [],
    };
    menu.push(item);
    commit("setMenuState", { id: item.id, state: false });

    commit("setMenuItems", menu);
  },
};

const mutations = {
  setMenuItems(state, payload) {
    state.menuItems = payload;
  },
  setHeaderState(state, payload) {
    state.headerState = payload;
  },
  setMenuState(state, payload) {
    state.menuState.push(payload);
  },
  setNavBar(state, payload) {
    state.navBar = payload;
  },
  updateMenuState(state, payload) {
    state.menuState.find((v) => {
      if (v.id == payload.id) {
        v.state = payload.state;
      }
    });
  },
};

const getters = {
  getMenuItems: (state) => state.menuItems,
  getHeaderState: (state) => state.headerState,
  getMenuItemState: (state) => (id) =>
    state.menuState.find((v) => {
      if (v.id == id) {
        return true;
      }
    }),

  getNavbar: (state) => state.navBar,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

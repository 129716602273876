const Home = () =>
  import(/* webpackChunkName: "sf-home" */ "../core/views/Home.vue");
const PageNotFound = () =>
  import(
    /* webpackChunkName: "sf-page-not-found" */ "../core/views/PageNotFound.vue"
  );
const Category = () =>
  import(/* webpackChunkName: "sf-category-page" */ "../core/views/Category.vue");

const Login = () =>
  import(/* webpackChunkName: "sf-login" */ "../core/views/Login.vue");

const ForgotPassword = () =>
  import(
    /* webpackChunkName: "sf-forgot-password" */ "../core/views/ForgotPassword.vue"
  );
const CreateAccount = () =>
  import(
    /* webpackChunkName: "sf-create-account" */ "../core/views/CreateAccount.vue"
  );
const Account = () =>
  import(/* webpackChunkName: "sf-create-account" */ "../core/views/Account.vue");
const CmsPage = () =>
  import(/* webpackChunkName: "sf-cms-page" */ "../core/views/StaticPage.vue");

const Product = () =>
  import(/* webpackChunkName: "sf-product" */ "../core/views/Product.vue");
const Cart = () =>
  import(/* webpackChunkName: "sf-cart" */ "../core/views/Cart.vue");
const Checkout = () =>
  import(/* webpackChunkName: "sf-checkout" */ "../core/views/Checkout.vue");
const PaymentSuccess = () =>
  import(
    /* webpackChunkName: "sf-payment-success" */ "../core/views/PaymentSuccess.vue"
  );
const PaymentCancel = () =>
  import(
    /* webpackChunkName: "sf-payment-cancel" */ "../core/views/PaymentCancel.vue"
  );
const OurShop = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/OurShop.vue");

const Search = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Search.vue");

const PartFinder = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/PartFinder.vue");
const ResetPassword = () =>
  import(
    /* webpackChunkName: "sf-forgot-password" */ "../core/views/ResetPassword.vue"
  );

const routes = [
  { name: "home", path: "/", component: Home },
  { name: "error", path: "/error", component: PageNotFound },
  { name: "login", path: "/login", component: Login },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: ForgotPassword,
  },
  { name: "create-account", path: "/create-account", component: CreateAccount },
  { name: "account", path: "/account", component: Account },
  { name: "en-login", path: "/en/login", component: Login },
  {
    name: "en-forgot-password",
    path: "/en/forgot-password",
    component: ForgotPassword,
  },
  {
    name: "en-create-account",
    path: "/en/create-account",
    component: CreateAccount,
  },
  { name: "en-account", path: "/en/account", component: Account },
  { name: "product-SimpleProduct", path: "/product", component: Product },
  { name: "product", path: "/product", component: Product },
  { name: "cms-page", path: "/cms-page", component: CmsPage },
  { name: "cart", path: "/checkout/cart", component: Cart },
  { name: "checkout", path: "/checkout", component: Checkout },
  { name: "ourshop", path: "/winkel/:code", component: OurShop },
  { name: "en-search", path: "/en/search", component: Search },
  { name: "search", path: "/search", component: Search },
  { name: "en-partfinder", path: "/en/partfinder", component: PartFinder },
  { name: "partfinder", path: "/partfinder", component: PartFinder },
  {
    name: "onpagesuccess",
    path: "/checkout/onepage/success",
    component: PaymentSuccess,
  },
  {
    name: "onpagecancel",
    path: "/checkout/onepage/cancel",
    component: PaymentCancel,
  },
  {
    name: "multisafepaysuccessen",
    path: "/dt1_en/multisafepay/connect/success",
    component: PaymentSuccess,
  },
  {
    name: "multisafepaycancelen",
    path: "/dt1_en/multisafepay/connect/cancel",
    component: PaymentCancel,
  },
  {
    name: "multisafepaysuccessnl",
    path: "/dt1_nl/multisafepay/connect/success",
    component: PaymentSuccess,
  },
  {
    name: "multisafepaycancelnl",
    path: "/dt1_nl/multisafepay/connect/cancel",
    component: PaymentCancel,
  },
  {
    name: "multisafepaysuccessnl",
    path: "/dt1_nl/multisafepay/connect/notification",
    component: PaymentSuccess,
  },

  { name: "category-page", path: "/category-page", component: Category },
  {
    name: "product-ConfigurableProduct",
    path: "/productconfig",
    component: Product,
  },
  {
    name: "product-page",
    path: "/product",
    component: Product,
  },
  {
    name: "reset-password",
    path: "/customer/account/resetpassword",
    component: ResetPassword,
  },
  {
    name: "dt1-nl-reset-password",
    path: "/dt1_nl/customer/account/resetpassword",
    component: ResetPassword,
  },
  { name: "page-not-found", path: "/page-not-found", component: PageNotFound },
];

export default routes;

import store from "@storefront/core/store";
import menu from "@/esf_lommel_dt1_europe/store/modules/menu";
import partfinder from "@/esf_lommel_dt1_europe/store/modules/partfinder";
//import { isServer } from "@storefront/core/helpers";
store.registerModule("menu", menu);
store.registerModule("partfinder", partfinder);
import Vue from "vue";
import config from "@config";

Vue.prototype.$link = function (link) {
    if (this.$i18n.locale != config.languages.default) {
        return "/" + this.$i18n.locale + link;
    } else {
        return link;
    }
};

export default store;

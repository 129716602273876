import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";
//import store from "@/store";

/**
 * load the partfinder filters
 *
 * @returns partfinder filter object
 */
export async function loadFilterPartfinder() {
  const lang = getCurrentLanguage();
  const storelang = config.languages[lang];
  const storeview = storelang["storeview"];

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + config.shop.accessToken,
    Store: storeview,
  };
  const query =
    "{ partfinder { configuration { allow_partnumber_search allow_search_save disallow_add_to_cart show_warning_add_to_cart show_warning_cart_page show_warning_product_page universal_products } types { id name brands { id name models { id name years { id name cilinders { id name } } } } } } }";
  const retval = await axios({
    url: config.shop.graphQLURL + "?query=" + encodeURIComponent(query),
    method: "GET",
    headers: headers,
  }).catch((e) => {
    Logger.error("loadFilterPartfinder", "data-resolver filter", e)();
    throw e;
  });
  return retval.data.data.partfinder;
}
